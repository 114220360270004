import React from "react"
import "../styles/notFound.scss"
import dogImage from "../images/main/404.jpg"


// import SEO from "../components/seo"

const NotFoundPage = ({ data }) => (


    <div className="notFound container">
        <p>Podana strona nie istnieje</p>
        <div className="imgContainer">
            <div className="notFoundImg">
                <img src={dogImage} alt="dog" />
            </div>
        </div>


    </div>


)

export default NotFoundPage
